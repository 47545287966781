import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './services/api.service';
import { EventService } from './services/event.service';
import { NotificationService } from './services/notification.service';
import { UiService } from './services/ui.service';
import { LocalStorageService } from './services/local-storage.service';
import { Subscription } from 'rxjs';
import { EventType } from './shared/types/base';
import { GeocoderService } from './services/geocoder.service';
import { MapsAPILoader } from '@agm/core';
import { Title } from '@angular/platform-browser';
import { HeaderComponent } from './components/header/header.component';
import { SwUpdate } from '@angular/service-worker';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  title = 'Space Of Sports';

  @ViewChild('menuDrawer') public menuDrawer: MatDrawer;
  @ViewChild('userDrawer') public userDrawer: MatDrawer;
  @ViewChild('headerComponent') headerComponent: HeaderComponent;
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private eventService: EventService,
    private apiService: ApiService,
    private uiService: UiService,
    private localStorageService: LocalStorageService,
    private mapsApiLoader: MapsAPILoader,
    private geocoderService: GeocoderService,
    private titleService: Title,
    private swUpdate: SwUpdate,
  ) {
    // TODO: check this for translate initiualization:
    // https://github.com/ngx-translate/core/issues/517
  }

  async ngOnInit(): Promise<void> {
    console.log(`BUILD CONFIG: ${environment.build}`);

    if (this.swUpdate.isEnabled) {
      this.subscriptions.add(
        this.swUpdate.available.subscribe(async (res) => {
          if (res.type === 'UPDATE_AVAILABLE') {
            console.log('Updating to new version');
            await this.swUpdate.activateUpdate().then(() => {
              console.log('New version applied');
              location.reload();
            });
          }
        }),
      );
      await this.swUpdate.checkForUpdate();
    }

    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
    const locale = this.localStorageService.getAppLang();
    if (locale) {
      await this.translateService.use(locale).toPromise();
      this.titleService.setTitle(this.translateService.instant('app'));
    }

    await this.mapsApiLoader.load().then(() => {
      this.geocoderService.init();
    });

    this.subscriptions.add(
      this.eventService.getObservable().subscribe((event) => {
        if (event.name === EventType.error && event.data.unauthorized) {
          this.apiService.clearLogin();
        }

        if (event.name === EventType.langChange) {
          this.titleService.setTitle(this.translateService.instant('app'));
        }
      }),
    );

    this.initGtag();
  }

  ngAfterViewInit(): void {
    this.apiService.init();
    this.notificationService.init();
    this.headerComponent.init();
    this.uiService.setMenuDrawer(this.menuDrawer);
    this.uiService.setRigthDrawer(this.userDrawer);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initGtag(): void {
    if (!environment.production) {
      return;
    }

    const gtagEl: HTMLScriptElement = document.createElement('script');
    gtagEl.async = true;
    gtagEl.type = 'text/javascript';

    const gtagId = environment.GOOGLE_ANALYTICS_KEY;

    if (!gtagId) {
      return;
    }

    gtagEl.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;

    const gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.type = 'text/javascript';
    gtagScript.textContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${gtagId}');
     `;

    document.head.appendChild(gtagEl);
    document.head.appendChild(gtagScript);

    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd && environment.production) {
          gtag('config', environment.GOOGLE_ANALYTICS_KEY, {
            page_path: event.urlAfterRedirects,
          });
        }
      }),
    );
  }
}
