import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { LoginComponent } from './components/login/login.component';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './components/alert/alert.component';
import { BaseComponent } from './base/base.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { UserComponent } from './components/user/user.component';
import { DatePipe, LOCATION_INITIALIZED } from '@angular/common';
import { RestorePasswordComponent } from './components/restore-password/restore-password.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { HeaderMessagesComponent } from './components/header-messages/header-messages.component';
import { HeaderNotificationsComponent } from './components/header-notifications/header-notifications.component';
import { ProfileHeaderComponent } from './components/profile-header/profile-header.component';
import { PhotoPickerComponent } from './components/photo-picker/photo-picker.component';
import { ChoosePhotoComponent } from './components/choose-photo/choose-photo.component';
import { PhotoPickerFromAlbumComponent } from './components/photo-picker-from-album/photo-picker-from-album.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { ProfileSettingsComponent } from './components/profile-settings/profile-settings.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { ProfileViewComponent } from './components/profile-view/profile-view.component';
import { AgmCoreModule } from '@agm/core';
import { ProfileEditCareerComponent } from './components/profile-edit-career/profile-edit-career.component';
import { ChangePhotoDescDialog, ProfilePhotosComponent } from './components/profile-photos/profile-photos.component';
import { BackToTopComponent } from './components/back-to-top/back-to-top.component';
import { RegisterOrgComponent } from './components/register-org/register-org.component';
import { OrgProfileComponent } from './org-profile/org-profile.component';
import { OrgProfileEditComponent } from './components/org-profile-edit/org-profile-edit.component';
import { OrgProfileSettingsComponent } from './components/org-profile-settings/org-profile-settings.component';
import { ProfileInfoWidgetComponent } from './widgets/profile-info-widget/profile-info-widget.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProfileFeedsComponent } from './components/profile-feeds/profile-feeds.component';
import { FeedComponent } from './components/feed/feed.component';
import { ProfilePhotosWidgetComponent } from './widgets/profile-photos-widget/profile-photos-widget.component';
import { HomeFeedsComponent } from './components/home-feeds/home-feeds.component';
import { HomeFilterComponent } from './components/home-filter/home-filter.component';
import { LoadPageButtonComponent } from './components/load-page-button/load-page-button.component';
import { HomeOrgsComponent } from './components/home-orgs/home-orgs.component';
import { OrgWidgetComponent } from './widgets/org-widget/org-widget.component';
import { UserWidgetComponent } from './widgets/user-widget/user-widget.component';
import { HomeUsersComponent } from './components/home-users/home-users.component';
import { OrgProfileMembersComponent } from './components/org-profile-members/org-profile-members.component';
import { UserInvitationOptionsComponent } from './widgets/user-invitation-options/user-invitation-options.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { LayoutModule } from '@angular/cdk/layout';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ProfileMembersWidgetComponent } from './widgets/profile-members-widget/profile-members-widget.component';
import { ProfileBadgesWidgetComponent } from './widgets/profile-badges-widget/profile-badges-widget.component';
import { StretchToBottomDirective } from './directives/stretch-to-bottom.directive';
import { MessagesComponent } from './components/messages/messages.component';
import { MessageOptionsComponent } from './widgets/message-options/message-options.component';
import { HeaderSideMenuComponent } from './components/header-side-menu/header-side-menu.component';
import { UserSideMenuComponent } from './components/user-side-menu/user-side-menu.component';
import { HomeMapComponent } from './components/home-map/home-map.component';
import { QuickSearchComponent } from './components/quick-search/quick-search.component';
import { QuickSearchResultComponent } from './components/quick-search-result/quick-search-result.component';
import { HomePhotosComponent } from './components/home-photos/home-photos.component';
import { PhotoFeedAuthorWidgetComponent } from './widgets/photo-feed-author-widget/photo-feed-author-widget.component';
import { CommentComponent } from './components/comment/comment.component';
import { LikeButtonComponent } from './widgets/like-button/like-button.component';
import { FavsWidgetComponent } from './widgets/favs-widget/favs-widget.component';
import { ProfileFavsComponent } from './components/profile-favs/profile-favs.component';
import { AboutUsComponent } from './static/about-us/about-us.component';
import { PrivacyPolicyComponent } from './static/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './static/terms-condition/terms-condition.component';
import { FavButtonComponent } from './widgets/fav-button/fav-button.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ApiService } from './services/api.service';
import { EnumService } from './services/enum.service';
import { LocalStorageService } from './services/local-storage.service';
import { SelectDefOrgComponent } from './components/select-def-org/select-def-org.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { InstaFollowComponent } from './components/insta-follow/insta-follow.component';
import { SuperAdminUsersComponent } from './components/super-admin-users/super-admin-users.component';
import { SuperAdminOrgsComponent } from './components/super-admin-orgs/super-admin-orgs.component';
import { AgePipe } from './pipes/age.pipe';
import { FlagPipe } from './pipes/flag.pipe';
import { MassMessagesComponent } from './mass-messages/mass-messages.component';
import { MassMessagesHistoryComponent } from './components/mass-messages-history/mass-messages-history.component';
import { NewFeedComponent } from './components/new-feed/new-feed.component';
import { AvatarUrlPipe } from './pipes/avatar-url.pipe';
import { ProfileReferralComponent } from './components/profile-set-suggestion-source/profile-referral.component';
import { ProfileSocialLinkPipe } from './widgets/profile-info-widget/pipes/profile-social-link.pipe';
import { TabMenuComponent } from './components/tab-menu/tab-menu.component';
import { MatSelectFilterComponent } from './components/mat-select-filter/mat-select-filter.component';
import { InputPhoneComponent } from './components/input-phone/input-phone.component';
import { DatePickerSimpleComponent } from './components/date-picker-simple/date-picker-simple.component';
import { TagsComponent } from './components/tags/tags.component';
import { UserNamePipe } from './pipes/user-name.pipe';
import { DeleteUserComponent } from './components/delete-user/delete-user.component';
import { UserWidgetSlimComponent } from './widgets/user-widget-slim/user-widget-slim.component';
import { SearchTypePipe } from './pipes/search-type.pipe';
import { RegisterReferralPageComponent } from './register-referral-page/register-referral-page.component';
import {
  ProfileReferralsOverviewComponent
} from './components/profile-referrals-overview/profile-referrals-overview.component';
import { ReferralsTableComponent } from './widgets/referrals-table/referrals-table.component';
import { AppPaginatorIntl } from './shared/types/paginator-intl';
import { EventService } from './services/event.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NotificationLinkPipe } from './components/header-notifications/pipes/notification-link.pipe';
import { OrgProfileLegendsComponent } from './components/org-profile-legends/org-profile-legends.component';
import { LegendFormComponent } from './components/org-profile-legends/components/legend-form/legend-form.component';
import {
  LegendFormDialogComponent
} from './components/org-profile-legends/components/legend-form-dialog/legend-form-dialog.component';
import {
  LegendsManageComponent
} from './components/org-profile-legends/components/legends-manage/legends-manage.component';
import {
  LegendWidgetComponent
} from './components/org-profile-legends/components/legend-widget/legend-widget.component';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { SportNamePipe } from './pipes/sport-name.pipe';
import { LocaleDatePipe } from './pipes/locale-date.pipe';
import { SearchGenericComponent } from './components/search-generic/search-generic.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PhonePipe } from './pipes/phone.pipe';
import {
  SuperAdminMobileTableComponent
} from './components/super-admin-mobile-table/super-admin-mobile-table.component';
import { VerifiedBadgeComponent } from './components/verified-badge/verified-badge.component';
import { RecommendButtonComponent } from './components/recommend-button/recommend-button.component';
import { OrgRecommendationsComponent } from './widgets/org-recommendations/org-recommendations.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { HomePhotoItemComponent } from './components/home-photos/components/home-photo-item/home-photo-item.component';
import { AdminEditUserWarningComponent } from './components/admin-edit-user-warning/admin-edit-user-warning.component';
import { WarningTextPipe } from './components/admin-edit-user-warning/pipes/warning-text.pipe';
import { ProfileDonationWidgetComponent } from './widgets/profile-donation-widget/profile-donation-widget.component';
import { HelpChildrenPageComponent } from './help-children-page/help-children-page.component';
import { HelpChildrenWidgetComponent } from './widgets/help-children-widget/help-children-widget.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(
  translate: TranslateService,
  storageService: LocalStorageService,
  apiService: ApiService,
  enumService: EnumService,
  injector: Injector): any {
    return () => new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {

        const langToSet = storageService.getAppLang();

        translate.setDefaultLang('en');
        translate.use(langToSet).subscribe(() => {
          console.log(`Successfully initialized '${langToSet}' language.'`);

        }, () => {
          console.error(`Problem with '${langToSet}' language initialization.'`);
        }, () => {
            apiService.getEnums().subscribe(
                (result) => {
                    enumService.setEnums(result.body);
                    resolve(null);
                },
                (error) => console.error(error) );
        });
      });
    });
}

export function paginatorIntlFactory(translateService: TranslateService, eventService: EventService): AppPaginatorIntl {
  return new AppPaginatorIntl(translateService, eventService);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    DeleteUserComponent,
    RegisterUserComponent,
    AlertComponent,
    BaseComponent,
    ActivateUserComponent,
    UserComponent,
    RestorePasswordComponent,
    PageNotFoundComponent,
    ProfileComponent,
    HeaderMessagesComponent,
    HeaderNotificationsComponent,
    ProfileHeaderComponent,
    PhotoPickerComponent,
    ChoosePhotoComponent,
    PhotoPickerFromAlbumComponent,
    AccountSettingsComponent,
    ProfileSettingsComponent,
    ProfileEditComponent,
    ProfileViewComponent,
    ProfileEditCareerComponent,
    ProfilePhotosComponent,
    ChangePhotoDescDialog,
    BackToTopComponent,
    RegisterOrgComponent,
    OrgProfileComponent,
    OrgProfileEditComponent,
    OrgProfileSettingsComponent,
    ProfileInfoWidgetComponent,
    SuperAdminComponent,
    ProfileFeedsComponent,
    FeedComponent,
    ProfilePhotosWidgetComponent,
    HomeFeedsComponent,
    HomeFilterComponent,
    LoadPageButtonComponent,
    HomeOrgsComponent,
    OrgWidgetComponent,
    UserWidgetComponent,
    HomeUsersComponent,
    OrgProfileMembersComponent,
    UserInvitationOptionsComponent,
    ImageViewerComponent,
    ProfileMembersWidgetComponent,
    ProfileBadgesWidgetComponent,
    StretchToBottomDirective,
    MessagesComponent,
    MessageOptionsComponent,
    HeaderSideMenuComponent,
    UserSideMenuComponent,
    HomeMapComponent,
    QuickSearchComponent,
    QuickSearchResultComponent,
    HomePhotosComponent,
    PhotoFeedAuthorWidgetComponent,
    CommentComponent,
    LikeButtonComponent,
    FavsWidgetComponent,
    ProfileFavsComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    FavButtonComponent,
    SelectDefOrgComponent,
    AutofocusDirective,
    InstaFollowComponent,
    SuperAdminUsersComponent,
    SuperAdminOrgsComponent,
    AgePipe,
    FlagPipe,
    MassMessagesComponent,
    MassMessagesHistoryComponent,
    NewFeedComponent,
    AvatarUrlPipe,
    ProfileReferralComponent,
    ProfileSocialLinkPipe,
    TabMenuComponent,
    MatSelectFilterComponent,
    InputPhoneComponent,
    DatePickerSimpleComponent,
    TagsComponent,
    UserNamePipe,
    UserWidgetSlimComponent,
    SearchTypePipe,
    RegisterReferralPageComponent,
    ProfileReferralsOverviewComponent,
    ReferralsTableComponent,
    NotificationLinkPipe,
    OrgProfileLegendsComponent,
    LegendFormComponent,
    LegendFormDialogComponent,
    LegendsManageComponent,
    LegendWidgetComponent,
    CountryNamePipe,
    SportNamePipe,
    LocaleDatePipe,
    SearchGenericComponent,
    PhonePipe,
    SuperAdminMobileTableComponent,
    VerifiedBadgeComponent,
    RecommendButtonComponent,
    OrgRecommendationsComponent,
    SafeHtmlPipe,
    HomePhotoItemComponent,
    AdminEditUserWarningComponent,
    WarningTextPipe,
    ProfileDonationWidgetComponent,
    HelpChildrenPageComponent,
    HelpChildrenWidgetComponent,
  ],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		StorageServiceModule,
		NgxPaginationModule,
		PerfectScrollbarModule,
		MatInputModule,
		MatCheckboxModule,
		MatSelectModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatMenuModule,
		MatSidenavModule,
		MatButtonToggleModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatDialogModule,
		MatSnackBarModule,
		MatIconModule,
		MatRadioModule,
		MatTableModule,
		MatProgressSpinnerModule,
		MatPaginatorModule,
		MatSortModule,
		LayoutModule,
		PickerModule,
		PdfViewerModule,
		AngularFireModule.initializeApp(environment.FIREBASE_PWA_CONFIG),
		AngularFireMessagingModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		AgmCoreModule.forRoot({
			apiKey: environment.GOOGLE_API_KEY,
			libraries: ['places', 'geometry'],
		}),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		NgbModule,
		MatSlideToggleModule,
		ImageCropperModule,
	],
  providers: [
      {
          provide: APP_INITIALIZER,
          useFactory: appInitializerFactory,
          deps: [TranslateService, LocalStorageService, ApiService, EnumService, Injector],
          multi: true
      },
      {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      },
      {
        provide: MatPaginatorIntl,
        deps: [TranslateService, EventService],
        useFactory: (translateService, eventService) => new AppPaginatorIntl(translateService, eventService),
      },
      DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
